#spinner-animation {
    opacity: 0;
    z-index: -9999;
    border: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left:0;
    transition: opacity 300ms linear, z-index 300ms step-end;
    backdrop-filter: blur(5px);
    background-color: #1773CF;

    &.show {
        opacity: .96;
        z-index: 9999;
        transition: opacity 300ms linear, z-index 300ms step-start;
    }
}
